<template>
  <div class="ele-body">
    <el-card shadow="never">
      <!-- 搜索表单 -->
<el-form :model="table.where" class="ele-form-search ele-form-mysearch d-flex"
	@keyup.enter.native="$refs.table.reload()" @submit.native.prevent>
	<el-form-item label="支付类型:" label-width="76px">
		<el-select v-model="table.where.payment" placeholder="请选择" clearable class="input150">
			<el-option label="微信" value="1" />
			<el-option label="支付宝" value="2" />
			<el-option label="零钱" value="3" />
			<el-option label="手动结束" value="0" />
		</el-select>
	</el-form-item>
	<el-form-item label="订单类型:" label-width="80px">
		<el-select v-model="table.where.type" placeholder="请选择" clearable class="input150">
			<el-option label="全部" :value="10" />
			<el-option label="巡游出租" :value="1" />
			<el-option label="城际定制" :value="3" />
			<el-option label="跑腿" :value="4" />
			<el-option label="商超" :value="5" />
		</el-select>
	</el-form-item>
	<el-form-item label="商家姓名:" label-width="76px">
		<el-input v-model="table.where.driver_name" placeholder="请输入商家姓名" class="input150" clearable />
	</el-form-item>
	<el-form-item label="商家手机号:" label-width="90px">
		<el-input v-model="table.where.driver_phone" placeholder="请输入商家手机号" class="input150" clearable />
	</el-form-item>
	<el-form-item label="用户昵称:" label-width="76px">
		<el-input v-model="table.where.user_name" placeholder="请输入用户昵称" class="input150" clearable />
	</el-form-item>
	<el-form-item label="用户手机号:" label-width="90px">
		<el-input v-model="table.where.user_phone" placeholder="请输入手机号" class="input150" clearable />
	</el-form-item>
	<el-form-item label="订单编号:" label-width="76px">
		<el-input v-model="table.where.order_id" placeholder="请输入订单号" class="input150" clearable />
	</el-form-item>
	<el-form-item label="省:" label-width="35px">
		<el-select v-model="table.where.pid" @change="handleChangeProv(table.where.pid)" placeholder='请选择省' class="input150" clearable>
			<el-option v-for="option in provArr" :value="option.pid" :key="option.pid" :label="option.name"></el-option>
		</el-select>
	</el-form-item>
	<el-form-item label="市:" label-width="35px">
		<el-select v-model="table.where.cid" @change="handleChangeCity(table.where.cid)" placeholder='请选择市' class="input150" clearable>
			<el-option v-for="option in cityArr" :value="option.cid" :key="option.cid" :label="option.name"></el-option>
		</el-select>
	</el-form-item>
	<el-form-item label="县/区:" label-width="62px">
		<el-select v-model="table.where.aid" @change="$forceUpdate()" placeholder='请选择县/区' class="input150" clearable>
			<el-option v-for="option in districtArr " :value="option.aid" :key="option.aid" :label="option.name">
			</el-option>
		</el-select>
	</el-form-item>
	<el-form-item label="开始时间:" label-width="76px">
		<el-date-picker v-model="table.where.create_time" type="date" placeholder="选择日期" clearable>
		</el-date-picker>
	</el-form-item>
	<el-form-item label="结束时间:" label-width="76px">
		<el-date-picker v-model="table.where.end_time" type="date" placeholder="选择日期" clearable>
		</el-date-picker>
	</el-form-item>
	<el-form-item>
		<el-button type="primary" @click="$refs.table.reload()" icon="el-icon-search" class="ele-btn-icon ml-20">搜索
		</el-button>
		<el-button @click="(table.where={})&&$refs.table.reload()">重置</el-button>
		<download style="margin-left: 0" class="ele-btn-icon ml-20" label="导出" url="finance/exportExcel"
			:params="exportOrderParams()" />
		<!--                  <el-button icon="el-icon-download" type="primary" @click="exportExcel" v-if="permission.includes('sys:order:export')">导出</el-button>-->
	</el-form-item>

</el-form>

<!-- 数据表格 -->
<ele-data-table ref="table" :config="table" :choose.sync="choose" height="calc(100vh - 315px)" highlight-current-row
	:stripe=true>
	<template slot-scope="{index}">
		<el-table-column type="selection" width="45" align="center" fixed="left" />
		<el-table-column type="index" :index="index" label="编号" width="60" align="center" fixed="left"
			show-overflow-tooltip />
		<el-table-column prop="set_area" label="地区" show-overflow-tooltip min-width="160" />
		<el-table-column prop="order_id" label="订单编号" show-overflow-tooltip min-width="200" />
		<el-table-column prop="type_name" label="订单类型" show-overflow-tooltip min-width="100" />
		<el-table-column prop="user_name" label="用户昵称" show-overflow-tooltip min-width="160" />
		<el-table-column prop="user_phone" label="用户手机号" show-overflow-tooltip min-width="120" />
		<el-table-column prop="driver_name" label="司机/商家姓名" show-overflow-tooltip min-width="120" />
		<el-table-column prop="driver_phone" label="司机/商家手机号" show-overflow-tooltip min-width="140" />
		<el-table-column prop="car_number" label="车牌号/所属公司/店铺名称" show-overflow-tooltip min-width="120" />
		<el-table-column prop="payment" label="支付方式" show-overflow-tooltip min-width="80">
			<template slot-scope="scope">
				<span>{{scope.row.payment==1?'微信':(scope.row.payment==2?'支付宝':(scope.row.payment==3?'零钱':'手动结束'))}}</span>
			</template>
		</el-table-column>
		<el-table-column prop="order_price" label="订单金额(元)" show-overflow-tooltip min-width="80" />
		<el-table-column prop="bonus" label="含零钱金额(元)" show-overflow-tooltip min-width="80" />
		<el-table-column prop="driver_get_money" label="实际到账(元)" show-overflow-tooltip min-width="80" />
		<el-table-column prop="handling_fee" label="手续费(元)" show-overflow-tooltip min-width="80" />
		<el-table-column prop="order_rule_fee" label="平台服务费(元)" show-overflow-tooltip min-width="80" />
		<el-table-column prop="time_out_fee" label="超时扣款(元)" show-overflow-tooltip min-width="80" />
		<el-table-column label="发单时间" show-overflow-tooltip min-width="160">
			<template slot-scope="{row}">{{ row.create_time*1000 | toDateString }}</template>
		</el-table-column>
		<el-table-column label="支付时间" show-overflow-tooltip min-width="160">
			<template slot-scope="{row}">{{ row.complete_time*1000 | toDateString }}</template>
		</el-table-column>
		<el-table-column prop="remark" label="备注" show-overflow-tooltip min-width="120" />
	</template>
</ele-data-table>
</el-card>



</div>
</template>
<style>
	.ele-form-mysearch {
		float: right
	}
</style>
<script>
	import download from '@/components/Download/index'
	import {
		mapGetters
	} from "vuex";

	export default {
		name: "SysUser",
		components: {
			download
		},
		data() {
			return {
				table: {
					url: '/finance/index',
					where: {}
				}, // 表格配置
				choose: [], // 表格选中数据
				editForm: {}, // 表单数据
				checkForm: {},
				editRules: { // 表单验证规则

				},
				dialogTableVisibleCheck: false,
				dialogTableVisibleChatRecord: false,
				provArr: [],
				cityArr: [],
				districtArr: [],
				audioArr: [],
				urgentList: [],
				imageUrl: false,
				headImg: '',
				zoom: 12,
				center: [104.07, 30.67]
			}
		},
		created() {
			this.$http.get('/common/province_list').then(res => {
				let data = JSON.parse(res.data)
				this.provArr = data
			})

		},


		computed: {
			...mapGetters(["permission"]),
		},

		mounted() {},
		methods: {
			exportOrderParams() {
				const query = this.table.where
				// const params = this.params
				const selectionIds = this.choose.map(item => {
					return item.id
				})

				return {
					ids: selectionIds,
					...query
				}
			},
			dropClick(command, row) {
				if (command === 'remove') { //删除
					this.remove()
				} else if (command === 'chat') { //紧急联系人
					this.handleChat(row)
				}
			},
			/**
			 *选择省
			 **/

			handleChangeProv(e) {
				/** 获取被选省份的pid**/
				let pid = ''
				this.provArr.forEach(function(item) {
					if (item.pid == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.table.where.cid = ''
					this.table.where.aid = ''
				})
			},
			/**
			 *选择市
			 **/
			handleChangeCity(e) {
				if(e){
				/** 获取被选市的cid**/
				let cid = ''
				this.cityArr.forEach(function(item) {
					if (item.cid == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					let data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.table.where.aid = ''
				})
				}else{
					this.table.where.pid = ''
					this.table.where.aid = ''
				}
			},


			/**
			 *修改省
			 **/
			handleChangeProv1(e) {
				//console.log(e)
				/** 获取被选省份的pid**/
				var pid = ''
				this.provArr.forEach(function(item) {
					if (item.name == e) {
						pid = item.pid
					}
				})
				/** 根据被选省份的pid获取省市下面的市**/
				this.$http.post('/common/city_list', {
					pid: pid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.cityArr = data
					/** 选择省份清空市县**/
					this.editForm.cname = ''
					this.editForm.aname = ''
				})
			},
			/**
			 *修改市
			 **/
			handleChangeCity1(e) {
				if(e){
				/** 获取被选市的cid**/
				var cid = ''
				this.cityArr.forEach(function(item) {
					if (item.name == e) {
						cid = item.cid
					}
				})
				/** 根据被选市的cid获取市下面的县**/
				this.$http.post('/common/area_list', {
					cid: cid
				}).then(res => {
					var data = JSON.parse(res.data)
					this.districtArr = data
					/** 选择省份清空县**/
					this.editForm.aname = ''
				})
				}else{
					this.editForm.pname = ''
					this.editForm.aname = ''
				}
			},


			exportExcel() {
				let info = JSON.parse(JSON.stringify(this.table.where));
				//console.log('order data export');
				this.$http
					.get("/finance/exportExcel", info)
					.then((res) => {
						let data = res.data;
						if (data.code == 0) {
							// 下载文件
							window.location.href = data.data;
							this.$message({
								type: "success",
								message: "导出成功",
							});
						} else {
							this.$message.error("导出失败");
						}
					})
			},
		}
	}
</script>

-->
